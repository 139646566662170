import {
  Flex,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'apis/api';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { Address, AddressSuggestionPopover } from 'component-library/components/address-suggestion-popover';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import { KInput } from 'component-library/components/Input';
import KCustomSelect from 'components/KCustomSelect';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { Customer, customerUpdateSchema } from 'types/customers';
import { LocationOptionType } from 'types/location';
import { toNormalCase } from 'utils';

import { AddressStatus } from './address-status';

type EditAddressModalProps = {
  customer: Customer;
  isOpen: boolean;
  onClose: () => void;
};

export const EditCustomerAddressModal = ({ customer, isOpen, onClose }: EditAddressModalProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { countries, getStates } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const { mutateAsync: doUpdateCustomerAddress, isPending } = useMutation<
    Customer,
    unknown,
    {
      orgId: string;
      customerId: string;
      payload: Partial<Customer>;
    }
  >({
    mutationFn: async ({ orgId, customerId, payload }) => {
      const res = await api.put(
        `/v1/customers/${customerId}`,
        { ...payload, organization_id: orgId },
        {
          headers: {
            'x-organization-id': orgId,
          },
        }
      );
      return res.data;
    },
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: [CUSTOMERS_STATE_KEY, orgId, data.id],
      });
      handleSuccessNotification('Customer address updated successfully');
    },
  });

  const initialValues = customerUpdateSchema
    .transform(({ city, ...rest }) => ({
      city: toNormalCase(city),
      ...rest,
    }))
    .cast(customer, { stripUnknown: true });

  const { errors, values, touched, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues,
    validationSchema: customerUpdateSchema,
    onSubmit: async payload => {
      await doUpdateCustomerAddress({ payload, orgId, customerId: customer.id });
      onClose();
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(values.country ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [values.country]);

  const handleUpdateAddress = (address: Address) => {
    setFieldValue('city', toNormalCase(address.city || ''));
    setFieldValue('state', address.state);
    setFieldValue('postal_code', address.postal_code);
  };

  const onCloseModal = () => {
    resetForm();
    onClose();
  };
  console.log('stateOptions', stateOptions);
  console.log('values.country', values.country);
  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <AddressStatus
            title="Edit Address"
            customer={customer}
            titleProps={{
              fontSize: '18px',
              lineHeight: '26px',
              fontWeight: 'medium',
              color: 'gray.900',
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={'column'} gap={4}>
            <HStack align={'baseline'} gap={4}>
              <FormControl isRequired>
                <FormLabel htmlFor="country">Country</FormLabel>
                <KCustomSelect
                  id="country"
                  name="country"
                  items={countries}
                  itemTitle="label"
                  itemValue="value"
                  value={values.country}
                  onChange={value => setFieldValue('country', value as string)}
                />
                {errors.country && touched.country && <Text color={'#E53E3E'}>{errors.country}</Text>}
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="state">State</FormLabel>
                <KCustomSelect
                  id="state"
                  name="state"
                  items={stateOptions}
                  itemTitle="label"
                  itemValue="value"
                  value={values.state}
                  onChange={value => setFieldValue('state', value as string)}
                />
                {errors.state && touched.state && <Text color={'red.500'}>{errors.state}</Text>}
              </FormControl>
            </HStack>
            <HStack align={'baseline'} gap={4}>
              <FormControl isRequired>
                <FormLabel htmlFor="city">City</FormLabel>
                <KInput
                  as="input"
                  id="city"
                  type="text"
                  name="city"
                  focusBorderColor="secondary.500"
                  onChange={handleChange}
                  value={values.city}
                />
                {errors.city && touched.city && <Text color={'red.500'}>{errors.city}</Text>}
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="postal_code">Postal Code</FormLabel>
                <KInput
                  id="postal_code"
                  type="string"
                  name="postal_code"
                  focusBorderColor="secondary.500"
                  onChange={handleChange}
                  value={values.postal_code}
                />
                {errors.postal_code && touched.postal_code && <Text color={'red.500'}>{errors.postal_code}</Text>}
              </FormControl>
            </HStack>
            <HStack align={'baseline'} gap={4}>
              <FormControl>
                <FormLabel htmlFor="street_1">Address Line 1</FormLabel>
                <KInput
                  as="input"
                  id="street_1"
                  type="text"
                  name="street_1"
                  focusBorderColor="secondary.500"
                  onChange={handleChange}
                  value={values.street_1}
                />
                {errors.street_1 && touched.street_1 && <Text color={'red.500'}>{errors.street_1}</Text>}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="street_2">Address Line 2</FormLabel>
                <KInput
                  as="input"
                  id="street_2"
                  type="text"
                  name="street_2"
                  focusBorderColor="secondary.500"
                  onChange={handleChange}
                  value={values.street_2}
                />
              </FormControl>
            </HStack>
            <HStack align={'baseline'} gap={4}>
              {isOpen && <AddressSuggestionPopover address={values as Address} onUpdateAddress={handleUpdateAddress} />}
            </HStack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => handleSubmit()}
              isLoading={isPending}
            >
              Save
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
