import { useAuthInfo } from '@propelauth/react';
import { useOrg } from 'hooks/useOrg';
import { createContext, PropsWithChildren, useContext } from 'react';

type ACLProviderType = {
  canDo: (permissions: string[]) => boolean;
  isAtLeastRole: (role: string) => boolean;
  isRole: (role: string) => boolean;
};

const defaultContext = {} as ACLProviderType;

const ACLContext = createContext<ACLProviderType>(defaultContext);

export const useACL = () => useContext(ACLContext);

export const useACLProvider = () => {
  const { accessHelper } = useAuthInfo();
  const { externalId } = useOrg();

  const canDo = (permissions: string[]) => {
    if (!accessHelper) return false;
    return accessHelper.hasAllPermissions(externalId, permissions);
  };

  const isAtLeastRole = (role: string) => {
    if (!accessHelper) return false;
    return accessHelper.isAtLeastRole(externalId, role);
  };

  const isRole = (role: string) => {
    if (!accessHelper) return false;
    return accessHelper.isRole(externalId, role);
  };

  return { canDo, isAtLeastRole, isRole };
};

export const ACLProvider = ({ children }: PropsWithChildren) => {
  const data = useACLProvider();
  return <ACLContext.Provider value={data}>{children}</ACLContext.Provider>;
};
