import { Flex } from '@chakra-ui/react';
import Text from 'component-library/components/Text';

type TableEmptyStatePropsType = {
  tableName: string;
  isFilterApplied?: boolean;
  isSearchApplied?: boolean;
  // If you want to customize the default message and helper text
  tableUniqueMessage?: string;
  uniqueHelperTxt?: string;
};

export const TableEmptyState = ({
  tableName,
  isFilterApplied,
  isSearchApplied,
  tableUniqueMessage,
  uniqueHelperTxt,
}: TableEmptyStatePropsType) => {
  const TABLE_MIN_HEIGHT = 'calc(100vh - 160px)'; // Subtract header,footer and extra padding height
  const messages = {
    DEFAULT: {
      main: `No ${tableName} Available`,
      helper: '',
    },
    SEARCH: {
      main: 'No Results Found',
      helper: "We couldn't find any matches. Try modifying your search or check for typos.",
    },
    FILTER: {
      main: 'No Results Found',
      helper: "We couldn't find any matches. Try modifying your filters or clearing them.",
    },
  };

  let tableEmptyStateType: 'DEFAULT' | 'SEARCH' | 'FILTER' = 'DEFAULT';
  if (isFilterApplied) {
    tableEmptyStateType = 'FILTER';
  } else if (isSearchApplied) {
    tableEmptyStateType = 'SEARCH';
  }
  const { main, helper } = messages[tableEmptyStateType];

  return (
    <Flex
      data-testid="table-empty-state"
      justifyContent={{ base: 'center', md: 'center', sm: 'flex-start' }}
      align={'center'}
      mt={{ base: '0px', md: '0px', sm: 10 }}
      justify="center"
      direction="column"
      minH={TABLE_MIN_HEIGHT}
      w="100%"
    >
      <Text fontSize={22} fontWeight="semibold">
        {tableUniqueMessage ?? main}
      </Text>
      {(uniqueHelperTxt || helper) && (
        <Text mt={4} color={'#5A5E77'}>
          {uniqueHelperTxt ?? helper}
        </Text>
      )}
    </Flex>
  );
};
