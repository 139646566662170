import { TransactionDetailsDrawer } from 'pages/Addresses/components/transaction-details.drawer';
import FilingHistoryDrawer from 'pages/Filing/components/history-filings/filing-history-drawer';

export const AppDrawers = (): React.ReactNode => {
  return (
    <>
      <FilingHistoryDrawer />
      <TransactionDetailsDrawer />
    </>
  );
};

export default AppDrawers;
