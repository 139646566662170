import { OrgMemberInfo, useAuthInfo, useLogoutFunction } from '@propelauth/react';
import { Tokens } from '@propelauth/react/dist/types/AuthContext';
import React, { useEffect, useRef } from 'react';
import { getOrgsFromPropelAuth } from 'utils/utils';

import { Organization, setOrg, useOrg } from './useOrg';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // ms

declare global {
  interface Window {
    __token_to_trpc?: string;
    __stored_token?: string;
    __selected_org_id?: string;
    __permitted_org_ids?: string[];
    __stored_org_token?: string;
    __first_org_token?: string;
    __tokens?: Tokens;
    __handle_org_token_error?: () => void;
    __force_logout?: (redirectOnLogout: boolean) => Promise<void>;
  }
}

export const clearOrgData = () => {
  window.__selected_org_id = undefined;
  window.__stored_org_token = undefined;
  setOrg({} as Organization);
};

export const clearOrgDataFromWindow = () => {
  window.__selected_org_id = undefined;
  window.__stored_org_token = undefined;
};

async function withRetry<T>(
  operation: () => Promise<T>,
  maxRetries: number = MAX_RETRIES,
  retryDelay: number = RETRY_DELAY
): Promise<T> {
  let retryCount = 0;
  while (retryCount < maxRetries) {
    try {
      return await operation();
    } catch (error) {
      if (retryCount < maxRetries && error instanceof Error) {
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      } else {
        throw error;
      }
    }
  }
  throw new Error('Max retries exceeded');
}

const getOrgAccessToken = async ({ externalId, tokens }: { externalId: string; tokens: Tokens }) => {
  const { accessToken, error } = await tokens.getAccessTokenForOrg(externalId);
  if (error) {
    throw error;
  }
  window.__selected_org_id = externalId;
  window.__stored_org_token = accessToken;
};

const getFirstOrgAccessToken = async ({ tokens, firstOrg }: { tokens: Tokens; firstOrg: OrgMemberInfo }) => {
  const { accessToken, error } = await tokens.getAccessTokenForOrg(firstOrg.orgId);
  if (error) {
    throw error;
  }

  window.__first_org_token = accessToken;
};

async function getOrgAccessTokenWithRetry({
  externalId,
  tokens,
}: {
  externalId: string;
  tokens: Tokens;
}): Promise<void> {
  return withRetry(() => getOrgAccessToken({ externalId, tokens }));
}

async function getFirstOrgAccessTokenWithRetry({
  tokens,
  firstOrg,
}: {
  tokens: Tokens;
  firstOrg: OrgMemberInfo;
}): Promise<void> {
  return withRetry(() => getFirstOrgAccessToken({ tokens, firstOrg }));
}

function useTrpcWithPropelAuth() {
  const { externalId } = useOrg();
  const { accessToken, tokens, orgHelper, loading, refreshAuthInfo } = useAuthInfo();
  const forceLogout = useLogoutFunction();

  const orgs = getOrgsFromPropelAuth(orgHelper?.getOrgs() || []);
  const orgIds = orgHelper?.getOrgIds() || [];
  const firstOrg = orgs.length > 0 ? orgHelper?.getOrg(orgs[0].external_id) : undefined;
  const firstOrgTokenFetchedRef = useRef(false);
  const firstOrgSelectRef = useRef(false);

  const handleOrgTokenError = async (error?: 'user_not_in_org' | 'unexpected_error') => {
    await refreshAuthInfo();
    if (error === 'user_not_in_org') {
      // If user not in org maybe we are impersonating with existing logged in account.
      // At this point we need to clearOrgData and reload page to remove existing
      // auth info.
      clearOrgData();
      window.location.reload();
    }
  };

  useEffect(() => {
    // If there is only 1 org then it should auto selected
    // For partner org initially 1 org should be auto selected
    if (!loading && !externalId && firstOrg && !firstOrgSelectRef.current && orgs.length === 1) {
      firstOrgSelectRef.current = true;
      setOrg({
        orgId: firstOrg.orgMetadata.organization_id,
        externalId: firstOrg.orgId,
        name: firstOrg.orgName,
        isTest: firstOrg.orgMetadata.is_test,
      });
    }

    // Gets the first org access token
    if (!loading && !externalId && firstOrg && !firstOrgTokenFetchedRef.current) {
      firstOrgTokenFetchedRef.current = true;
      getFirstOrgAccessTokenWithRetry({ tokens, firstOrg }).catch(handleOrgTokenError);
    }
  }, [loading, externalId, orgs]);

  useEffect(() => {
    if (externalId) {
      getOrgAccessTokenWithRetry({ externalId, tokens }).catch(handleOrgTokenError);
    }
  }, [externalId]);

  useEffect(() => {
    window.__permitted_org_ids = orgIds;
  }, [orgIds]);

  useEffect(() => {
    window.__tokens = tokens;
  }, [tokens]);

  useEffect(() => {
    window.__token_to_trpc = accessToken || undefined;
    window.__stored_token = window.__token_to_trpc;
  }, [accessToken]);

  useEffect(() => {
    window.__handle_org_token_error = handleOrgTokenError;
  }, [handleOrgTokenError]);

  useEffect(() => {
    window.__force_logout = forceLogout;
  }, [forceLogout]);
}

export function WithTrpcWithPropelAuth({ children }: { children?: React.ReactNode }) {
  useTrpcWithPropelAuth();
  return children;
}

export function getAuthorizationHeader() {
  const storedOrgToken = window.__stored_org_token || window.__token_to_trpc || window.__stored_token;

  return storedOrgToken ? `Bearer ${storedOrgToken}` : undefined;
}

export function getFirstOrgAuthorizationHeader() {
  return window.__first_org_token ? `Bearer ${window.__first_org_token}` : undefined;
}

export function getIsOrgIdValid() {
  const storedToken = window.__token_to_trpc || window.__stored_token;
  const permittedOrgIds = window.__permitted_org_ids || [];
  const selectedOrgId = window.__selected_org_id;

  if (selectedOrgId && storedToken) {
    return permittedOrgIds.includes(selectedOrgId);
  }

  return true;
}

export async function refetchOrgToken() {
  window.__stored_org_token = undefined;
  const selectedOrgId = window.__selected_org_id;
  const tokens = window.__tokens;
  const handleOrgTokenError = window.__handle_org_token_error;

  if (selectedOrgId && tokens) {
    // If there is selectedOrgId && tokens we need to auto refetch the organization access token
    getOrgAccessTokenWithRetry({ externalId: selectedOrgId, tokens }).catch(handleOrgTokenError);
  } else {
    // If there is no selectedOrgId we need to clearOrgData from local storage
    clearOrgData();
  }
}

export function handleLogout(redirectOnLogout: boolean) {
  window.__force_logout?.(redirectOnLogout);
}

export function getTokenIsTooLong() {
  const storedToken = window.__token_to_trpc || window.__stored_token;

  return (storedToken || '').length > 5000;
}

export function resetGloballyStoredValues() {
  window.__token_to_trpc = undefined;
  window.__stored_token = undefined;
  window.__selected_org_id = undefined;
  window.__permitted_org_ids = undefined;
  window.__stored_org_token = undefined;
  window.__first_org_token = undefined;
  window.__tokens = undefined;
  window.__handle_org_token_error = undefined;
  window.__force_logout = undefined;
}
