import { Flex, Icon, Spinner, Table, TableProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import { ReactNode, useState } from 'react';
import { IconType } from 'react-icons';

import ColumnFilter from './ColumnFilter';

type KDataTableProps = {
  onboardingTable?: string;
  headers: string[];
  numericCols?: string[];
  centerColumns?: string[];
  sortHeaders?: string[];
  handleSort?: (header: string) => string;
  getSortIcon?: (header: string) => IconType;
  children: ReactNode;
  tableStyle?: TableProps;
  defaultVisibleColumns?: string[];
  showColumnFilter?: boolean;
  getHeaderRightIcon?: (header: string) => ReactNode;
  onVisibleColumnsChange?: (columns: string[]) => void;
  height?: string;
  columnWidths?: Record<string, string>;
  isPending?: boolean;
};

export const KDataTable = ({
  onboardingTable = '',
  headers,
  numericCols = [],
  tableStyle,
  children,
  defaultVisibleColumns = headers,
  showColumnFilter = false,
  onVisibleColumnsChange,
  centerColumns,
  sortHeaders,
  handleSort,
  getSortIcon,
  getHeaderRightIcon,
  height,
  columnWidths,
  isPending,
}: KDataTableProps) => {
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultVisibleColumns);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
    if (onVisibleColumnsChange) {
      onVisibleColumnsChange(columns);
    }
  };

  const visibleHeaders = headers.filter(header => visibleColumns.includes(header));

  return (
    <TableContainer data-onboarding={onboardingTable} height={height} overflowX="auto" position="relative">
      <Table variant="unstyled" {...tableStyle}>
        <Thead>
          <Tr>
            {visibleHeaders.map((header: string, idx: number) => {
              const isSortableCol = sortHeaders?.includes(header);
              return (
                <Th
                  key={`${idx}-${header}`}
                  isNumeric={numericCols.includes(header)}
                  textAlign={centerColumns?.includes(header) ? 'center' : undefined}
                  onClick={() => isSortableCol && handleSort?.(header)}
                  width={columnWidths?.[header]}
                  style={{ minWidth: columnWidths?.[header] }}
                >
                  <Flex
                    justifyContent={numericCols.includes(header) ? 'flex-end' : undefined}
                    alignItems={'center'}
                    cursor={isSortableCol ? 'pointer' : 'default'}
                  >
                    {header}
                    {getHeaderRightIcon && getHeaderRightIcon(header)}
                    {isSortableCol && getSortIcon && <Icon as={getSortIcon(header)} fontSize={'18px'} />}
                  </Flex>
                </Th>
              );
            })}
            {showColumnFilter && (
              <Flex justifyContent={'flex-end'}>
                <ColumnFilter headers={headers} onToggleColumn={handleToggleColumn} visibleColumns={visibleColumns} />
              </Flex>
            )}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
      {isPending && (
        <Flex pos="absolute" top="0" right="0" w="full" h="full" bg="white" opacity={0.8} justify="center" zIndex="100">
          <Spinner marginTop={100} size="lg" />
        </Flex>
      )}
    </TableContainer>
  );
};
