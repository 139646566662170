import { Icon, Input, InputGroup, InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { KCustomSelectMainInputProps } from 'types/kCustomSelectType';

export const MainInput = forwardRef<HTMLInputElement, KCustomSelectMainInputProps>(
  ({ title = '', isOpen, onOpen, placeholder, isLoading, isDisabled, inputLeftContent, ...rest }, ref) => {
    const activeColor = 'secondary.500';
    let dropdownLeftIconColor = 'gray.800';
    if (isDisabled) {
      dropdownLeftIconColor = 'gray.200';
    } else if (isOpen) {
      dropdownLeftIconColor = activeColor;
    }

    return (
      <InputGroup onClick={() => !(isDisabled || isLoading) && onOpen()}>
        {!!inputLeftContent && <InputLeftElement>{inputLeftContent}</InputLeftElement>}
        <Input
          type="text"
          ref={ref}
          placeholder={placeholder}
          isReadOnly
          value={title}
          fontSize={'sm'}
          color={isDisabled ? 'gray.400' : 'gray.900'}
          borderColor={isOpen ? activeColor : 'gray.100'}
          isDisabled={isLoading || isDisabled}
          cursor={isLoading || isDisabled ? 'not-allowed' : 'pointer'}
          {...rest}
        />
        <InputRightElement>
          {isLoading ? (
            <Spinner size="xs" color="#4285F4" />
          ) : (
            <Icon
              as={MdArrowDropDown}
              color={dropdownLeftIconColor}
              fontSize={'xl'}
              _hover={{ cursor: isDisabled || isLoading ? 'not-allowed' : 'pointer' }}
            />
          )}
        </InputRightElement>
      </InputGroup>
    );
  }
);

MainInput.displayName = 'MainInput';
