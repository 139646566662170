import { SourceEnum } from 'schema/types-schema.d';

import BigCommerceTab from './bigcommerce';
import ChargebeeTab from './chargebee';
import { HrisTab } from './hris';
import { DeelIcon } from './icons/deel';
import { RipplingIcon } from './icons/rippling';
import QuickbooksTab from './quickbooks';
import ShopifyTab from './shopify';
import StripeTab from './stripe';
import WooCommerceTab from './woocommerce';

export const integrationTabs = [
  {
    title: 'All',
    cards: [
      {
        source: SourceEnum.BIGCOMMERCE,
        component: <BigCommerceTab />,
      },
      {
        source: SourceEnum.CHARGEBEE,
        component: <ChargebeeTab />,
      },
      {
        source: SourceEnum.QUICKBOOKS,
        component: <QuickbooksTab />,
      },
      {
        source: SourceEnum.STRIPE,
        component: <StripeTab />,
      },
      {
        source: SourceEnum.SHOPIFY,
        component: <ShopifyTab />,
      },
      {
        source: SourceEnum.WOOCOMMERCE,
        component: <WooCommerceTab />,
      },
      {
        source: SourceEnum.DEEL,
        component: (
          <HrisTab
            serviceId="deel"
            icon={<DeelIcon />}
            heading={'Deel'}
            subHeading={'Deel - Your forever people platform.'}
          />
        ),
      },
      {
        source: SourceEnum.RIPPLING,
        component: (
          <HrisTab
            serviceId="rippling"
            icon={<RipplingIcon />}
            heading={'Rippling'}
            subHeading={'Streamline HR and IT management.'}
          />
        ),
      },
    ],
  },
  {
    title: 'Billing',
    cards: [
      { source: SourceEnum.CHARGEBEE, component: <ChargebeeTab /> },
      { source: SourceEnum.STRIPE, component: <StripeTab /> },
    ],
  },
  {
    title: 'E-commerce',
    cards: [
      {
        source: SourceEnum.SHOPIFY,
        component: <ShopifyTab />,
      },
      {
        source: SourceEnum.BIGCOMMERCE,
        component: <BigCommerceTab />,
      },
      {
        source: SourceEnum.WOOCOMMERCE,
        component: <WooCommerceTab />,
      },
    ],
  },
  {
    title: 'Accounting',
    cards: [
      {
        source: SourceEnum.QUICKBOOKS,
        component: <QuickbooksTab />,
      },
    ],
  },
  {
    title: 'HR',
    cards: [
      {
        source: SourceEnum.DEEL,
        component: (
          <HrisTab
            serviceId="deel"
            icon={<DeelIcon />}
            heading={'Deel'}
            subHeading={'Deel - Your forever people platform.'}
          />
        ),
      },
      {
        source: SourceEnum.RIPPLING,
        component: (
          <HrisTab
            serviceId="rippling"
            icon={<RipplingIcon />}
            heading={'Rippling'}
            subHeading={'Streamline HR and IT management.'}
          />
        ),
      },
    ],
  },
];
