import { Button, Tooltip } from '@chakra-ui/react';
import { ArrowRightIcon, PartnerOrgIcon } from 'components/icons';
import { PARTNER_ORG } from 'constants/AppRoutes';
import React from 'react';
import { Link } from 'react-router-dom';

type PartnerOrgSelectorProps = {
  isExpanded: boolean;
};

export const PartnerOrgSelector = ({ isExpanded }: PartnerOrgSelectorProps) => {
  if (!isExpanded) {
    return (
      <Tooltip label="View Organizations" placement="bottom-end">
        <Button
          bgColor={'primary.500'}
          as={Link}
          to={PARTNER_ORG}
          flexWrap={'nowrap'}
          variant="nav"
          textColor={'gray.900'}
          justifyContent={'space-between'}
          height={'36px'}
          width={'unset'}
          p={1}
          borderRadius={'4px'}
          mx={2}
          style={{ width: 'unset' }}
          _hover={{
            bgColor: 'none',
          }}
          _active={{
            bgColor: 'none',
          }}
        >
          <PartnerOrgIcon width={'20'} />
          <ArrowRightIcon width={'10'} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      bgColor={'primary.500'}
      as={Link}
      to={PARTNER_ORG}
      flexWrap={'nowrap'}
      variant="nav"
      textColor={'gray.900'}
      justifyContent={'space-between'}
      height={'36px'}
      width={'auto'}
      p={3}
      borderRadius={'4px'}
      mx={2}
      _hover={{
        bgColor: 'none',
      }}
      _active={{
        bgColor: 'none',
      }}
    >
      Organizations
      <ArrowRightIcon width={'20'} />
    </Button>
  );
};
