import { Button, Flex, FlexProps, Icon, Select, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { MdArrowDropDown, MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { UseTableFilters } from 'types/shared-types';

type PaginationButtonsProps = {
  size: number;
  currentPage: number;
  totalPages: number;
  styles?: FlexProps;
  options?: {
    value: number;
    label: string;
  }[];
  setFilters: (filters: UseTableFilters) => void;
};

export const PaginationButtons = ({
  currentPage,
  totalPages,
  size,
  styles = {},
  options = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  setFilters,
}: PaginationButtonsProps) => {
  const pages: React.ReactElement[] = [];
  const maxPageButtonsToShow = 1;

  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 ||
      i === totalPages ||
      i === currentPage ||
      (i >= currentPage - maxPageButtonsToShow && i <= currentPage + maxPageButtonsToShow)
    ) {
      pages.push(
        <Tooltip label={String(i)} key={i} backgroundColor={'#262B47'}>
          <Button
            border={'1px solid rgba(207, 208, 216, 0.60)'}
            variant={'paging'}
            key={i}
            textAlign={'center'}
            onClick={() => setFilters({ page: i, size })}
            padding={'2px 2px'}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="40px"
            style={{
              backgroundColor: currentPage === i ? '#4285F4' : '#FFFFFF',
            }}
          >
            <Text isTruncated style={{ color: currentPage === i ? '#FFFFFF' : '#383D58' }}>
              {String(i)}
            </Text>
          </Button>
        </Tooltip>
      );
    } else if (i === currentPage - maxPageButtonsToShow - 1 || i === currentPage + maxPageButtonsToShow + 1) {
      pages.push(
        <button
          key={i}
          disabled={true}
          style={{
            margin: '5px',
            padding: '5px 10px',
            backgroundColor: 'white',
            color: 'black',
            cursor: 'not-allowed',
          }}
        >
          ...
        </button>
      );
    }
  }

  return (
    <Flex maxW={'full'} flexDirection={'row'} alignSelf={'center'} alignItems={'center'} {...styles}>
      <Button
        border={'1px solid rgba(207, 208, 216, 0.60)'}
        variant={'paging'}
        onClick={() => setFilters({ page: currentPage - 1, size })}
        isDisabled={currentPage === 1}
        style={{
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
        }}
      >
        <Icon as={MdOutlineArrowBackIos} />
      </Button>
      {pages}
      <Button
        border={'1px solid rgba(207, 208, 216, 0.60)'}
        variant={'paging'}
        onClick={() => setFilters({ page: currentPage + 1, size })}
        isDisabled={currentPage === totalPages}
        style={{
          cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
        }}
      >
        <Icon as={MdOutlineArrowForwardIos} />
      </Button>
      <Text mx={'2'} whiteSpace={'nowrap'}>
        Per Page
      </Text>
      <Select
        variant={'spaced'}
        icon={<MdArrowDropDown />}
        h={'9'}
        w={'18'}
        value={size}
        onChange={e => {
          const newSize = parseInt(e.target.value, 10);
          setFilters({ page: 1, size: newSize });
        }}
        textColor={'gray.800'}
        cursor="pointer"
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Flex>
  );
};
