import { Tooltip } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useACL } from 'app/acl/acl';
import Button from 'component-library/components/ButtonTmp/button';
import { useOrg } from 'hooks/useOrg';
import { useSearchParams } from 'react-router-dom';

export const PartnerOrgToolbar = () => {
  const { orgId } = useOrg();
  const { user } = useAuthInfo();
  const { isAtLeastRole } = useACL();
  const isOwner = isAtLeastRole('Owner');

  const [, setSearchParams] = useSearchParams();

  const handleClick = () => {
    setSearchParams(params => {
      const currentParams = Object.fromEntries([...params]);
      return {
        ...currentParams,
        createOrg: orgId,
        isPartner: 'true',
      };
    });
  };

  return (
    <Tooltip
      label={
        isOwner || user?.properties?.partner_id
          ? 'Click to create organization'
          : `You don't have permission to create a test organization. Only the organization owner can do so.`
      }
      placement="left"
    >
      <Button
        isDisabled={!isOwner && !user?.properties?.partner_id}
        variant={'solid'}
        color={'primary'}
        width={'120px'}
        onClick={handleClick}
      >
        New
      </Button>
    </Tooltip>
  );
};
