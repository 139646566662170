import { Alert, AlertDescription, AlertDescriptionProps, AlertIcon, AlertProps } from '@chakra-ui/react';
import { MdInfo } from 'react-icons/md';

type AlertBannerProps = {
  message: string;
  alertDescriptionProps?: AlertDescriptionProps;
} & AlertProps;

const AlertBanner = ({ message = '', alertDescriptionProps, ...rest }: AlertBannerProps) => {
  return (
    <Alert
      status="info"
      alignItems={{ base: 'center', md: 'center', sm: 'flex-start' }}
      overflow={{ base: 'hidden', md: 'hidden', sm: 'visible' }}
      {...rest}
    >
      <AlertIcon as={MdInfo} mr={2}></AlertIcon>
      <AlertDescription {...alertDescriptionProps}>{message}</AlertDescription>
    </Alert>
  );
};

export default AlertBanner;
