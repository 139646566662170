import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useTracking from './useTracking';

const usePageTracking = (): void => {
  const location = useLocation();
  const { pageLoad } = useTracking();

  useEffect(() => {
    try {
      pageLoad();
    } catch (error) {
      console.error('Failed to track page load:', error);
      captureException(error, {
        tags: {
          type: 'page_tracking_error',
          component: 'usePageTracking',
        },
      });
    }
  }, [location]);
};

export default usePageTracking;
