import { Badge, Button, Flex, HStack, IconButton, Td, Tr, useClipboard } from '@chakra-ui/react';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { CopyIcon } from 'components/icons';
import { useOrg } from 'hooks/useOrg';
import { PartnerOrganization } from 'types/organizations';

type PartnerOrgTableRowProps = PartnerOrganization;

export const PartnerOrgTableRow = ({
  id,
  name,
  billing_plan,
  pending_tasks,
  unpaid_filings,
  unregistered_registrations,
  onOrgSwitch,
  external_id,
  is_test,
}: PartnerOrgTableRowProps) => {
  const { onCopy, hasCopied } = useClipboard(id);
  const { orgId } = useOrg();

  const handleClick = () => {
    onOrgSwitch({
      orgId: id,
      name: name,
      externalId: external_id,
      isTest: is_test,
    });
  };

  return (
    <Tr _hover={{ bg: 'gray.50' }} role="group">
      <Td width={'100px'}>
        <Flex alignItems="center">
          <Tooltip label={id} shouldWrapChildren>
            <Text isTruncated maxWidth={'100px'}>
              {id}
            </Text>
          </Tooltip>
          <Tooltip
            label={hasCopied ? 'Copied!' : 'Copy'}
            isOpen={hasCopied ? true : undefined}
            closeDelay={hasCopied ? 1500 : 0}
          >
            <IconButton
              aria-label="Copy"
              icon={<CopyIcon />}
              size="sm"
              variant="transparent-with-icon"
              onClick={onCopy}
              opacity={0}
              _groupHover={{ opacity: 1 }}
            />
          </Tooltip>
        </Flex>
      </Td>
      <Td>
        <HStack>
          <Tooltip label={name} shouldWrapChildren>
            <Text isTruncated maxWidth={'150px'}>
              {name}
            </Text>
          </Tooltip>
          {orgId === id && (
            <Badge width={'60px'} variant="blue">
              Current
            </Badge>
          )}
        </HStack>
      </Td>
      <Td>
        <Text>{billing_plan}</Text>
      </Td>
      <Td>
        <Text>{unregistered_registrations}</Text>
      </Td>
      <Td>
        <Text>{unpaid_filings}</Text>
      </Td>
      <Td>
        <Text>{pending_tasks}</Text>
      </Td>
      <Td display="flex" flexDirection="row" justifyContent={'space-between'} width={'220px'} alignItems={'center'}>
        <Text>{is_test ? 'Test' : 'Live'}</Text>
        <Button
          variant="outline"
          fontSize={'14px'}
          padding={'4px 8px'}
          opacity={0}
          _groupHover={{ opacity: 1 }}
          bg={'gray.50'}
          onClick={handleClick}
        >
          View Organization
        </Button>
      </Td>
    </Tr>
  );
};
