import { Maybe } from './utils';

export type UsersRequest = {
  pageSize: number;
  pageNumber: number;
  includeOrgs?: boolean;
  role?: string;
};

export type OrgMetadata = {
  is_test?: boolean;
  env?: string;
  organization_id?: string;
};

export type User = {
  user_id: string;
  email: string;
  email_confirmed: boolean;
  first_name?: string;
  last_name?: string;
  username?: string;
  picture_url?: string;
  properties?: Record<string, any>;
  locked: boolean;
  enabled: boolean;
  has_password: boolean;
  update_password_required: boolean;
  mfa_enabled: boolean;
  can_create_orgs: boolean;
  created_at: number;
  last_active_at: number;
  user_role?: string;
  user_permissions?: string[];
  additional_roles?: string[];
  inherited_user_roles_plus_current_role?: string[];
  org_metadata?: OrgMetadata;
  legacy_user_id?: string;
};

export type UsersResponse = {
  total_users: number;
  current_page: number;
  page_size: number;
  has_more_results: boolean;
  users: User[];
};

export type UserResponseWithById = UsersResponse & { usersById: Record<string, User> };

export type InviteResponse = {
  invitee_email: string;
  org_id: string;
  org_name: string;
  role_in_org: string;
  additional_roles: Maybe<string[]>;
  created_at: number;
  expires_at: number;
  inviter_email: Maybe<string>;
  inviter_user_id: Maybe<string>;
};

export type InvitesResponse = {
  invites: InviteResponse[];
  total_invites: number;
  page_size: number;
  current_page: number;
  has_more_results: boolean;
};

export enum ActiveAndInvitedUserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
}

export type ActiveAndInvitedUser = Pick<User, 'user_id' | 'email' | 'user_role' | 'user_permissions'> & {
  status: ActiveAndInvitedUserStatus;
};
export type ActiveAndInvitedUsers = Pick<UsersResponse, 'current_page' | 'page_size' | 'has_more_results'> & {
  users: ActiveAndInvitedUser[];
};

export type ActiveAndInvitedUsersById = ActiveAndInvitedUsers & { usersById: Record<string, ActiveAndInvitedUser> };

export type APIKeyResponse = {
  api_key_id: string;
  created_at: number;
  expires_at_seconds?: number;
  metadata?: Record<string, any>;
  user_id: string;
  org_id: string;
};

export type APIKeysResponse = {
  api_keys: APIKeyResponse[];
  total_api_keys: number;
  current_page: number;
  page_size: number;
  has_more_results: boolean;
};

export type APIKeysRequest = {
  active?: boolean;
  pageSize: number;
  pageNumber: number;
};

export type APIKeyRequest = {
  expires_at_seconds?: number;
  metadata?: Record<string, any>;
};

export type ApiKeyCreateResponse = {
  api_key_id: string;
  api_key_token: string;
};

export type Organization = {
  orgId: string;
  name: string;
  externalId: string;
  isTest: boolean;
};

export type PartnerOrganization = {
  id: string;
  name: string;
  billing_plan: string;
  unpaid_filings: number;
  unregistered_registrations: number;
  pending_tasks: number;
  type: string;
  external_id: string;
  is_test: boolean;
  onOrgSwitch: (org: Organization) => void;
};

export type PartnerOrganizationsRequest = {
  page: number;
  size: number;
  query?: string;
};

export const OrganizationType = {
  LIVE: 'live',
  TEST: 'test',
};

export type PartnerOrganizationsResponse = {
  items: PartnerOrganization[];
  total: number;
  pages: number;
};
