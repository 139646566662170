import { Flex, Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import { AddressCard } from 'components/Address/AddressCard';
import { CustomerInstance } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

import { EditCustomerAddressModal } from './edit-customer-address-modal';

const replaceNullWithEmpty = (obj: any) => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key) && obj[key] === null) {
      obj[key] = '';
    }
  }
  return obj;
};

export const CustomerInfo = ({ data }: { data: CustomerInstance }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)', sm: 'repeat(1, 1fr)' }} gap={4}>
        <GridItem colSpan={{ base: 1, md: 3 }} border="1px solid #EFEFF3">
          <Flex h="100%" p={6} gap={{ base: 1, md: 1, sm: 4 }} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Flex direction={'column'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
              <Text fontWeight={500}>ID</Text>
              <Text>{data.external_id}</Text>
            </Flex>
            <Flex direction={'column'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
              <Text fontWeight={500}>Name</Text>
              <Text>{data.name}</Text>
            </Flex>

            <Flex direction={'column'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
              <Text fontWeight={500}>Status</Text>
              <Text>{getHumanReadableString(data.status)}</Text>
            </Flex>
            <Flex direction={'column'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
              <Text fontWeight={500}>Email</Text>
              <Text>{data.email}</Text>
            </Flex>

            <Flex direction={'column'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
              <Text fontWeight={500}>Phone Number</Text>
              <Text>{data.phone}</Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <AddressCard title="Address" address={data} addressStatus={data?.address_status} onClickEdit={onOpen} />
        </GridItem>
      </Grid>
      {data && isOpen && (
        <EditCustomerAddressModal customer={replaceNullWithEmpty(data)} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};
