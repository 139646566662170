import { httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import App from 'app/app';
import flagsmith from 'flagsmith';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';

const env = import.meta.env.VITE_ENVIRONMENT ?? 'local';
const sampleRate = env === 'prod' ? 0.3 : 0.1;
const startTime = Date.now(); // Record the start time when the script begins execution
const waitTime = 1000 * 3 * 60; // 3 minutes

const updateSW = registerSW({
  onNeedRefresh() {
    const endTime = Date.now(); // Get the current time
    const elapsed = endTime - startTime; // Calculate the elapsed time since the script started
    if (elapsed < waitTime) {
      updateSW();
      return;
    }
    if (confirm('New content available. Reload?')) {
      updateSW(true);
    }
  },
});

if (env === 'prod' || env === 'dev') {
  Sentry.init({
    environment: env,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      httpClientIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: sampleRate,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [/https?:\/\/app\.posthog\.com/],
    ignoreErrors: [
      'TypeError: Failed to fetch',
      "Cannot read properties of undefined (reading 'identify')",
      "TypeError: Cannot read properties of undefined (reading 'identify')",
      "Cannot read properties of undefined (reading 'page')",
      "TypeError: Cannot read properties of undefined (reading 'page')",
      "Cannot read properties of undefined (reading 'track')",
      "TypeError: Cannot read properties of undefined (reading 'track')",
      'Data Source Import',
      "Cannot read properties of undefined (reading 'capture')",
      "TypeError: Cannot read properties of undefined (reading 'capture')",
      "Cannot read properties of undefined (reading 'register')",
      "TypeError: Cannot read properties of undefined (reading 'register')",
      /Request cancelled:[\s\S]*/,
    ],
    beforeSend(event) {
      if (event?.tags) {
        event.tags['selected_org'] = localStorage.getItem('selectedOrg') ?? 'not found';
      }
      return event;
    },
  });
} //Add this line if you're using flagsmith via npm

flagsmith.init({
  environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT,
});

TagManager.initialize({
  gtmId: 'GTM-5RXL9KD6',
  auth: 'Kv8hQX8TtyRVg0-48DKDWw',
  preview: 'env-1',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
