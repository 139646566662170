import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  clearOrgData,
  getAuthorizationHeader,
  getFirstOrgAuthorizationHeader,
  getIsOrgIdValid,
  handleLogout,
  refetchOrgToken,
  resetGloballyStoredValues,
} from 'hooks/useTrpcWithPropelAuth';
import isArray from 'lodash/isArray';

axios.defaults.baseURL = import.meta.env.VITE_API_URL || 'http://localhost:8000';
axios.defaults.withCredentials = false;

const api = axios;
const URLS_NEEDS_TO_USE_FIRST_ORG_TOKEN = ['/v1/partner/organizations', '/v1/organizations/'];

const CancelToken = axios.CancelToken;

/**
 * axios request interceptors
 */
axios.interceptors.request.use(function (config) {
  const authorizationHeader = getAuthorizationHeader();
  const isOrgIdValid = getIsOrgIdValid();
  if (!authorizationHeader || (authorizationHeader || '').length > 4999 || !isOrgIdValid) {
    // Cancel the request if no authorization header or is too long
    const firstOrgAuthorizationHeader = getFirstOrgAuthorizationHeader();
    if (firstOrgAuthorizationHeader && URLS_NEEDS_TO_USE_FIRST_ORG_TOKEN.includes(config.url || '')) {
      // We always need to show list of organization from the db
      config.headers.Authorization = firstOrgAuthorizationHeader;
      if (import.meta.env.VITE_ENVIRONMENT === 'local') {
        config.headers['ngrok-skip-browser-warning'] = '1234';
      }
      return config;
    }

    return {
      ...config,
      cancelToken: new CancelToken(cancel =>
        cancel(
          `Request cancelled:\n` +
            `-> Reason: No or Too Long authorization header\n` +
            `-> Endpoint: ${config?.url || 'unknown endpoint'}\n` +
            `-> Method: ${config?.method?.toUpperCase() ?? 'unknown method'}`
        )
      ),
    };
  }

  config.headers.Authorization = authorizationHeader;
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    config.headers['ngrok-skip-browser-warning'] = '1234';
  }
  return config;
});

// Helper functions
const handleBadRequest = (error: ApiError) => {
  console.error('Bad request', error);
};

const handleUnauthorized = () => {
  // If unauthorized we should logout the user completely.
  clearOrgData();
  resetGloballyStoredValues();
  handleLogout(true);
};

const handleForbidden = () => {
  // If there is 403 then we need to refetch the org
  // token again since it is expired at this point.
  refetchOrgToken();
};

const handleMethodNotAllowed = (error: ApiError) => {
  if (error.response) {
    error.response.data = {
      detail: 'Method Not Allowed. Try with a different method.',
    };
  }
};

const handleInternalServerError = (error: ApiError) => {
  console.error('Internal server error', error);
};

/**
 * main axios response interceptors
 */
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError): Promise<AxiosError> => {
    const apiError = error as ApiError;
    const status = apiError.response?.status;

    const errorHandlers: Record<number, (error: ApiError) => void> = {
      400: handleBadRequest,
      401: handleUnauthorized,
      403: handleForbidden,
      405: handleMethodNotAllowed,
      500: handleInternalServerError,
    };

    if (status && status in errorHandlers) {
      errorHandlers[status](apiError);
    } else if (axios.isCancel(error)) {
      console.warn(error.message);
    } else {
      console.error(`Unhandled status code: ${status}`, apiError);
    }

    return Promise.reject(error);
  }
);
export type ApiError = AxiosError;

export default api;

export const handleApiErrorMessage = (err: any) => {
  try {
    if (axios.isCancel(err) || err.name === 'AbortError') {
      // xhr's Promise was cancelled, probably by our code with AbortController.abort()
      return;
    }
    return parseMessageFromError(err);
  } catch (error) {
    console.error('apiError', error);
  }
};

export const parseMessageFromError = (error: any): { title: string; message: string } => {
  let title = '';
  let message = '';
  const errorData = error?.response?.data;
  title = error?.message;

  if (errorData?.detail && isArray(errorData?.detail)) {
    message = errorData.detail[0].msg;
  } else if (errorData?.detail?.msg) {
    message = errorData.detail.msg;
  } else if (errorData?.detail) {
    message = errorData.detail;
  }

  // please add more cases here if needed
  return { title, message };
};

export const isAxiosError = (error: any): error is AxiosError => {
  return !!error?.response;
};

/**
 * Check the error is an Axios error and it matches the specified status codes.
 *
 * @param {any} error - The error object.
 * @param {...number[]} statusCodes - The status codes to check against.
 * @returns {boolean} True if the error is an Axios error with any of the specified status codes, false otherwise.
 */
export const isAxiosErrorMatchedStatus = (error: any, ...statusCodes: number[]) => {
  return isAxiosError(error) && statusCodes.includes(error.response?.status || 0);
};

export const staticErrorMessages = {
  400: 'Bad request. Please check your input.',
  401: 'Unauthorized. Please log in.',
  403: 'Forbidden. You do not have permission to perform this action.',
  404: 'Resource not found.',
  500: 'Oops! Something went wrong. Please try again or contact support if the issue persists.',
};
