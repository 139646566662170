import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryParam = (queryName: string, defaultValue?: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryValue = searchParams.get(queryName);
  const [value, setParamValue] = useState<any>(defaultValue);

  useEffect(() => {
    setParamValue(queryValue);
  }, [queryValue]);

  return [value, setSearchParams];
};

export default useQueryParam;
