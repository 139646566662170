import { Flex, HStack, IconButton, Select, VStack } from '@chakra-ui/react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

interface CalendarHeaderProps extends ReactDatePickerCustomHeaderProps {}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeMonth,
  changeYear,
}) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const years = Array.from({ length: 20 }, (_, i) => date.getFullYear() - 10 + i);

  return (
    <Flex p={4} pt={1} pb={1} w="100%" justifyContent={'space-between'} align={'center'}>
      <VStack spacing={1} align="start">
        <HStack spacing={2}>
          <Select size="sm" value={date.getMonth()} onChange={e => changeMonth(parseInt(e.target.value))} width="auto">
            {months.map((month, index) => (
              <option key={month} value={index}>
                {month}
              </option>
            ))}
          </Select>
          <Select
            size="sm"
            value={date.getFullYear()}
            onChange={e => changeYear(parseInt(e.target.value))}
            width="auto"
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </HStack>
      </VStack>
      <HStack gap={2}>
        <IconButton
          size="sm"
          variant={'ghost'}
          aria-label="prev-month"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <MdChevronLeft fontSize={'1.5rem'} color="#000" />
        </IconButton>
        <IconButton
          size="sm"
          aria-label="next-month"
          variant={'ghost'}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <MdChevronRight fontSize={'1.5rem'} color="#000" />
        </IconButton>
      </HStack>
    </Flex>
  );
};

export default CalendarHeader;
