import { Select, SelectProps } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useState } from 'react';
import { MdOutlineArrowDropDown } from 'react-icons/md';

const defaultProps: SelectProps = {
  icon: <MdOutlineArrowDropDown fontSize={'1.3rem'} color="#000" />,
};

const KS_Select = ({ value: selectedValue, ...rest }: SelectProps, ref: any) => {
  // hijack value prop to perform side effect
  const [value, setValue] = useState<SelectProps['value']>('');

  useEffect(() => {
    setValue(selectedValue || '');
  }, [selectedValue]);

  return (
    <Select
      {...defaultProps}
      value={value}
      {...rest}
      ref={ref}
      sx={{
        color: value === '' ? 'gray.600' : 'inherit',
      }}
    />
  );
};

export { type SelectProps };
export default forwardRef(KS_Select);
