import { useEffect, useState } from 'react';

const useIsEmbeddedInIframe = (): boolean => {
  const [isEmbeddedInIframe, setIsEmbeddedInIframe] = useState<boolean>(false);

  useEffect(() => {
    const isEmbeddedInIframe = () => {
      return window.top !== window.self;
    };

    if (isEmbeddedInIframe()) {
      setIsEmbeddedInIframe(true);
    }
  }, []);

  return isEmbeddedInIframe;
};

export default useIsEmbeddedInIframe;
