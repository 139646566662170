import debounce from 'lodash/debounce';
import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import AppLayoutMd from './app-layout.md';
import AppLayoutSm from './app-layout.sm';

export const AppLayout = (): React.ReactNode => {
  const mediaQueryList = window.matchMedia('(min-width: 768px)');
  const [matches, setMatches] = useState<boolean>(mediaQueryList.matches);

  useEffect(() => {
    const handler = debounce((e: MediaQueryListEvent) => setMatches(e.matches), 200);
    mediaQueryList.addEventListener('change', handler);

    return () => {
      handler.cancel(); // Cancel any pending debounced calls
      mediaQueryList.removeEventListener('change', handler);
    };
  }, [mediaQueryList]);

  return !matches ? (
    <AppLayoutSm>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </AppLayoutSm>
  ) : (
    <AppLayoutMd>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </AppLayoutMd>
  );
};
